import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/analytics";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.firestore = app.firestore();
    this.analytics = app.analytics();

    try {
      this.messaging = app.messaging();
    } catch (e) {
      this.messaging = false;
      console.log(e);
    }

    // google sign-in
    this.googleAuthProvider = new app.auth.GoogleAuthProvider();

    // apple sign-in
    this.appleAuthProvider = new app.auth.OAuthProvider("apple.com");
    this.appleAuthProvider.addScope("email");
    this.appleAuthProvider.addScope("name");

    this.auth.setPersistence(app.auth.Auth.Persistence.LOCAL);
  }
}

const firebase = new Firebase();
export default firebase;
