import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";

import Header from "../../components/header";

const Footer = lazy(() => import("../../components/footer"));

class MainLayout extends React.Component {
  render() {
    return (
      <div>
        <Header {...this.props} />
        <div>{React.Children.toArray(this.props.children)}</div>
        <Suspense fallback={<div></div>}>
          {this.props.showFooter && <Footer />}
        </Suspense>
      </div>
    );
  }
}

MainLayout.propTypes = {
  hideFooter: PropTypes.bool,
};

export default MainLayout;
