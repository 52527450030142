import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainLayout from "./layouts/header-content-footer";

const Home = lazy(() => import("./containers/home"));
const Login = lazy(() => import("./containers/login"));
const Forgot = lazy(() => import("./containers/forgot"));
const Console = lazy(() => import("./containers/console"));
const Terms = lazy(() => import("./containers/legal/terms"));
const Privacy = lazy(() => import("./containers/legal/privacy"));
const PageNotFound = lazy(() => import("./containers/404"));

const WrapMainLayout = (Component, layoutProps) => (props) => (
  <MainLayout {...layoutProps} {...props}>
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  </MainLayout>
);

const Loading = () => (
  <div
    style={{ minHeight: "30vh", height: "30vh" }}
    className="container flex-center"
  >
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={WrapMainLayout(Home, { showFooter: true })}
        />

        <Route path="/login" render={WrapMainLayout(Login)} />

        <Route path="/forgot" render={WrapMainLayout(Forgot)} />

        {/*user routes*/}
        <Route path="/console" render={WrapMainLayout(Console)} />

        {/*Legal*/}
        <Route
          exact
          path="/terms"
          render={WrapMainLayout(Terms, { showFooter: true })}
        />
        <Route
          exact
          path="/privacy"
          render={WrapMainLayout(Privacy, { showFooter: true })}
        />

        {/*404*/}
        <Route render={WrapMainLayout(PageNotFound)} />
      </Switch>
    </Router>
  );
}

export default App;
