import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import firebase from "../../helpers/firebase";
import "./styles.css";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navCollapsed: true,
      user: firebase.auth.currentUser,
    };
  }

  _onToggleNav = () => {
    this.setState({ navCollapsed: !this.state.navCollapsed });
  };

  componentDidMount() {
    const that = this;
    this.authListener = firebase.auth.onAuthStateChanged(function (user) {
      that.setState({ user });
    });
  }

  componentWillUnmount() {
    if (this.authListener) {
      this.authListener();
    }
  }

  render() {
    const { user } = this.state;
    const { location } = this.props;

    const headerStyle =
      location.pathname === "/"
        ? {
            backgroundColor: "#FFF0D7",
            padding: 16,
          }
        : {
            boxShadow: "0 0 3rem rgb(0 0 0 / 10%)",
            padding: 6,
          };

    return (
      <div style={{ ...headerStyle, transition: "all 250ms" }}>
        <div className={"container"}>
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand" to={"/"}>
              <img src={logo} width="40" height="40" alt={"Push by Techulus"} />
              <span className="extra-bold brand-name">
                <b>Push</b>
              </span>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              aria-expanded="false"
              onClick={this._onToggleNav}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              style={{ justifyContent: "flex-end" }}
              className={
                (this.state.navCollapsed ? "collapse" : "") + " navbar-collapse"
              }
              id="navbarNav"
            >
              <ul className="navbar-nav text-black-50">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://zapier.com/developer/public-invite/7743/eacfd29c4087cb67e7798c9876698682/"
                    }
                  >
                    Zapier
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://www.integromat.com/en/integrations/techulus-push?utm_source=techulus-push&utm_medium=partner&utm_campaign=techulus-push-partner-program"
                    }
                  >
                    Integromat
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://github.com/marketplace/actions/send-push-notification"
                    }
                  >
                    GitHub
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"https://docs.push.techulus.com/api-documentation"}
                  >
                    Documentation
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://pushbytechulus.freshdesk.com/support/solutions"
                    }
                  >
                    Support
                  </a>
                </li>

                {user ? (
                  <Link
                    style={{ marginLeft: 10 }}
                    className="btn btn-secondary extra-bold"
                    to={"/console"}
                  >
                    <span role={"img"} aria-label={"Dashboard"}>
                      🎮
                    </span>{" "}
                    &nbsp; Dashboard
                  </Link>
                ) : (
                  <Link
                    style={{ marginLeft: 10 }}
                    className="btn btn-secondary extra-bold"
                    to={"/login"}
                  >
                    <span role={"img"} aria-label={"Login"}>
                      🎮
                    </span>{" "}
                    &nbsp; Login
                  </Link>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Header;
